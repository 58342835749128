import AnonymousLayout from "../layouts/AnonymousLayout";
import MainLayout from "../layouts/MainLayout";

import Dashboard from '../containers/MainContent/dashboard/dashboard';
import Login from '../containers/MainContent/Auth/Login';
import Logout from '../containers/MainContent/Auth/Logout';
import Pages404 from '../containers/MainContent/pages/pages-404';
import Pages500 from '../containers/MainContent/pages/pages-500';
import Allorgs from '../containers/MainContent/orgs/all-orgs';
import Activeorgs from '../containers/MainContent/orgs/active-orgs';
import ViewOrg from '../containers/MainContent/orgs/view-org';
import ViewCampaign from '../containers/MainContent/campaigns/view-campaign';
import ViewEmails from '../containers/MainContent/orgs/view-emails';
import SignupRequests from '../containers/MainContent/orgs/signup-requests';
import { renderRoutes } from "./generate-routes";
import { getLoggedInUser } from "../helpers/Utils";
import axios from "axios";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import ClubTypes from "../containers/MainContent/dashboard/clubtypes";
import Groups2Icon from '@mui/icons-material/Groups2';

export const routes = [
    {
        layout: AnonymousLayout,
        isPublic: true,
        routes: [
            {
                name: 'login',
                title: 'Login page',
                component: <Login />,
                path: '/login',
                isPublic: true,
            },
            {
                name: 'logout',
                title: 'Logout',
                component: <Logout />,
                path: '/logout'
            }
        ]
    },
    {
        layout: MainLayout,
        routes: [
            {
                name: 'home',
                title: 'Home page',
                component: <Dashboard />,
                path: '/home',
                isSideMenu: true,
                icon: <DashboardIcon/>
            },
            {
                name: 'clubtypes',
                title: 'Club Types',
                component: <ClubTypes />,
                path: '/clubtypes',
                isSideMenu: true,
                icon: <Groups2Icon/>
            },
            { 
                title: 'All Organisations',
                name: 'all-orgs',
                path: '/all-orgs', 
                component: <Allorgs />,
                isSideMenu: true,
                icon: <AccountBalanceIcon />
            },
            { 
                title: 'Active Orgs',
                name: '/active-orgs',
                path: '/active-orgs', 
                component: <Activeorgs />,
                isSideMenu: true,
                icon: <ElectricBoltIcon />
            },          
            { 
                title: 'View Org Details', 
                name: 'view-org-details', 
                path: '/view-org/:orgid', 
                component: <ViewOrg />
            },
            { 
                title: 'View Campaign Details', 
                name: 'view-campaign-details', 
                path: '/view-campaign/:campaignid', 
                component: <ViewCampaign /> 
            },
            {
                title: 'View Emails', 
                name: 'view-emails', 
                path: '/view-emails', 
                component: <ViewEmails /> 
            },
            { 
                title: 'Signup Requests', 
                name: 'signup-requests', 
                path: '/signup-requests', 
                component: <SignupRequests />,
                isSideMenu: true,
                icon: <FollowTheSignsIcon />
            },
        
            { 
                title: '404', 
                name: 'pages-404', 
                path: '/pages-404', 
                component: <Pages404 />
            },
            { 
                title: '500', 
                name: 'pages-500', 
                path: '/pages-500', 
                component: <Pages500 />
            },
        
            { 
                title: 'Home Collapsed', 
                name: 'layout-collapsed-sidebar', 
                path: '/layout-collapsed-sidebar', 
                component: <Dashboard />
            },
            { 
                title: 'Home Light', 
                name: 'layout-light-sidebar', 
                path: '/layout-light-sidebar', 
                component: <Dashboard />
            },
        ]
    }
];

(function() {
    var user = getLoggedInUser();

    if (user != null) {
        axios.defaults.headers.common['x-access-token'] = user.token;
    } else {
        axios.defaults.headers.common['x-access-token'] = null;
        /*if setting null does not remove `Authorization` header then try
          delete axios.defaults.headers.common['Authorization'];
        */
    }
})();

export const Routes = renderRoutes(routes);

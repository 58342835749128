import { combineReducers  } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
import Login from './auth/login/reducer';

const rootReducer = combineReducers({

    // public
    Layout,

    // Authentication
    Login,

});

export default rootReducer;
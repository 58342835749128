import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import './App.css';
import { isUserAuthenticated } from './helpers/Utils';


import { Routes } from './routes';
import { history } from './helpers/history';
import './custom.css';
// import './App.scss';


const App = () => {
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <Routes isAuthorized={isUserAuthenticated()} />
  );
}

export default App;
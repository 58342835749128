import React, { Component } from 'react';
import { connect } from 'react-redux';
import { activateNonAuthLayout } from '../../../store/actions';
import { withRouter } from '../../../components/elements/withRouter';
import { history } from '../../../helpers/history';


class Logout extends Component {
    
    constructor(props) {
        super(props);
        this.state = {  };
    }

    componentDidMount() {
     
        this.props.activateNonAuthLayout();

        // Remove all Item from localstorage and redirect to login page
        localStorage.removeItem('user');

        // call logout on the server
        // return axios.post('/auth/logout', {}).then(response => {
        //     if (response.status !== 200)
        //         throw response.data;
        //     return response.data;
        // }).catch(err => {
        //     throw err[1];
        // });
        //
        history.navigate('/login');
    }

    render() {
        return (
            <React.Fragment>
               <h1>&nbsp;</h1>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
     return {  };
}

export default withRouter(connect(mapStatetoProps,{ activateNonAuthLayout })(Logout));


import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ isPublic, isAuthorized }) => {
  if(isPublic || isAuthorized) {
    return (
      <Outlet />
    )
  }
  return (<Navigate to='/login' />);
}

export default ProtectedRoute;
import React, { Component } from 'react';
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { formatCurrency, getApiRoot } from "../../../helpers/Utils"
import { Grid, ToggleButtonGroup, Stack, ToggleButton, Divider, Avatar, Button, Typography } from '@mui/material';
import { DashCard } from '../../../components/elements/DashCard';
import { red, green } from '@mui/material/colors';
import ValueLabel from '../../../components/elements/ValueLabel';
import Loading from '../Subpages/LoadingPleaseWait';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// import Apexbar from "../../charts/apex/apexbar";

function ClubTypes() {
  const [clubTypes, setClubTypes] = React.useState([]);
  const [sorting, setSorting] = React.useState('totalfees');
  const navigate = useNavigate();

  React.useEffect(() => {
    axios.get(getApiRoot() + "/api/metrics?type=clubtype").then(response => {
      if (response.status !== 200)
        throw response.data;

      // console.log("Fetched metrics ");
      // console.log(response.data);
      setClubTypes(sort(sorting, response.data))

    })
  }, []);

  const handleChange = (v) => {
    setSorting(v.target.value);
    // const clubTypeList = JSON.parse(JSON.stringify(clubTypes));
    // clubTypeList.sort((a,b) => {
    //   switch(v.target.value) {
    //     case 'totalfees':
    //       return b.stats.last12monthsapplicationfee - a.stats.last12monthsapplicationfee;
    //     case 'averagefees':
    //       return b.stats.averageapplicationfees - a.stats.averageapplicationfees;
    //     default:
    //       return b.stats.active - a.stats.active;
    //   }
    // });
    setClubTypes(sort(v.target.value, clubTypes));
  }

  const sort = (by, cts) => {
    const clubTypeList = JSON.parse(JSON.stringify(cts));
    clubTypeList.sort((a,b) => {
      switch(by) {
        case 'totalfees':
          return b.stats.last12monthsapplicationfee - a.stats.last12monthsapplicationfee;
        case 'averagefees':
          return b.stats.averageapplicationfees - a.stats.averageapplicationfees;
        default:
          return b.stats.active - a.stats.active;
      }
    });
    return clubTypeList;
  }

  return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row">
            <h5>Org Type Stats</h5>
            <ToggleButtonGroup 
              size='small'
              color="warning" 
              value={sorting}
              exclusive
              onChange={handleChange}
              aria-label="clubtypes"
              sx={{ml:2}}
            >
              <ToggleButton value="totalfees">Total Fees</ToggleButton>
              <ToggleButton value="averagefees">Average Fees</ToggleButton>
              <ToggleButton value="numorgs">Num Orgs</ToggleButton>
            </ToggleButtonGroup>
            <Divider sx={{py:2}}/>
          </Stack>
        </Grid>
        {clubTypes.length === 0 && <Grid item xs={12}><Loading /></Grid>}
        {clubTypes.map ((ct, idx) => {
          return (<Grid item md={3}>
            <DashCard 
              title={ct.name} 
              key={idx}
              avatar={<Avatar sx={{ bgcolor: green[500] }} aria-label="num orgs">{ct.stats.active - ct.stats.activeold}</Avatar>}
            >
              <ValueLabel value={formatCurrency(ct.stats.last12monthsapplicationfee)} label="Total Fees (12 months)" />
              <ValueLabel value={formatCurrency(ct.stats.averageapplicationfees)} label="Average Fees (12 months)" />
              <ValueLabel value={formatCurrency(ct.stats.maxfees)} label="Max Club Fees" />
              <ValueLabel value={formatCurrency(ct.stats.minfees)} label="Min Club Fees" />
              <ValueLabel value={formatCurrency(ct.stats.last12monthstotalvalue)} label="Total Value (12 months)" />
              <ValueLabel value={ct.stats.signeduplast30days} label="Signed Up (Last Month)" />
              <ValueLabel value={ct.stats.signeduplast12months} label="Signed Up (Last Year)" />
              <ValueLabel value={ct.stats.activatedlast30days} label="Activated (Last Month)" />
              <ValueLabel value={ct.stats.activatedlast12months} label="Activated (Last Year)" />
              <ValueLabel value={ct.stats.activeold} label="Active Elapsed" />           
              <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2} sx={{mt:2}}>
                <Button sx={{mt:1, py:0.15}} endIcon={<ChevronRightIcon/>} size="small" variant="outlined" color="success" onClick={() => navigate(`/all-orgs?clubtype=${ct.id}`)}>See Orgs</Button>
                <Typography sx={{ color: 'text.secondary' }} variant="caption">{ct.contactemail}</Typography>
              </Stack>
            </DashCard>
          </Grid>)
        })}
    </Grid>
  )

}

export default ClubTypes;



import React from 'react';
import { Outlet } from 'react-router-dom';

const AnonymousLayout = () => {
    
    return (
        <Outlet />
      )
  }
  
  export default AnonymousLayout;
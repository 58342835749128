import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

// Login Redux States
import {CHECK_LOGIN} from './actionTypes';
import {apiLoginError, loginUserSuccessful} from './actions';
// AUTH related methods
import {postLogin} from '../../../helpers/authUtils';
import { getApiRoot, setLoggeedInUser } from '../../../helpers/Utils';
import { history } from '../../../helpers/history';

// import Pageslogin from "../../../containers/MainContent/Auth/Login";

//If user is login then dispatch redux action's are directly from here.
function* loginUser({payload: {username, password}}) {
  
  try {
    const response = yield call(postLogin, getApiRoot() + '/api/auth/login', {username: username, password: password});
    console.log("Logged in user with: ", response);

    setLoggeedInUser(response);
    yield put(loginUserSuccessful(response));    
    history.navigate('/home');
  } catch (error) {
    yield put(apiLoginError("Invalid Credentials"));
  }
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser)
}

function* loginSaga() {
  yield all([fork(watchUserLogin)]);
}

export default loginSaga;
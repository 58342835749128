import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row} from 'reactstrap';
import {MDBDataTable} from 'mdbreact';
import {activateAuthLayout} from '../../../store/actions';
import {connect} from 'react-redux';
import LoadingPleaseWait from '../Subpages/LoadingPleaseWait';
import {Link} from 'react-router-dom';
import axios from "axios";
import {formatDateTime, getApiRoot, getStatus} from "../../../helpers/Utils";
import { withRouter } from '../../../components/elements/withRouter';


class AllCampaigns extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      isLoading: true,
      page: 1,
      sort: "desc",
      sortcolumn: 'id',
      campaignname: '',
      data: {
        columns: [
          {
            label: 'Id',
            field: 'id',
            sort: 'asc',
            width: 20
          }, {
            label: 'EMail',
            field: 'email',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Status',
            field: 'status',
            width: 80
          }, {
            label: 'Properties',
            field: 'properties',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Created',
            field: 'created',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Sent',
            field: 'sent',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Opened',
            field: 'opened',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Unsubscribed',
            field: 'unsubscribed',
            sort: 'asc',
            width: 100
          }
        ],
        rows: []
      }
    };
    console.log("View campaigns constructor");
  }

  componentDidMount() {
    this.props.activateAuthLayout();

    var parts = this.props.location.search.split("=");
    this.state.campaignname = decodeURIComponent(parts[1]);
    console.log(this.props);
    this._asyncRequest = this.load_data(this.props.params.campaignid).then(
      response => {
        this._asyncRequest = null;
        this.state.data.rows = response;
        this.setState({
          data: this.state.data,
          isLoading: false
        });
      }
    );

  }

  getProperties(properties) {
    var obj = JSON.parse(properties);
    var res = "";
    for (const [key, value] of Object.entries(obj)) {
      res += (res == '' ? value : "/" + value);
    }
    if(res == "") {
      return "none";
    }
    return res;
  }

  getStatus(status) {
    var statii = {
      opened: 'success',
      'email-sent': 'info',
      undelivered: 'warning',
      unsubscribed: 'secondary',
      clicked: 'dark',
      resent: 'primary',
      created: 'light',
      failed: 'danger'
    };
    var statusclass = "badge badge-" + statii[status];
    return <span class={statusclass}>{status}</span>;
  }

  async load_data(campaignid) {
    var _this = this;

    return axios.get(getApiRoot() + "/api/campaignrecipients/"+campaignid).then(response => {
      if (response.status !== 200)
        throw response.data;

      var rows = [];
      response.data.forEach(function (item, index) {
        // console.log("Returned sub type: " + stype);
        item.sent = item.sent != '' ? formatDateTime(item.sent) : "N/A";
        item.opened = item.opened != '' ? formatDateTime(item.opened) : "N/A";
        item.unsubscribed = item.unsubscribed != '' ? formatDateTime(item.unsubscribed) : "N/A";
        item.created = formatDateTime(item.created);
        item.properties = _this.getProperties(item.properties);
        item.status = _this.getStatus(item.status);
        rows.push(item);

      });
      console.log("Fetched " + response.data.length);
      return rows;
    }).catch(err => {
      throw err[1];
    });


  }


  render() {
    if (this.state.isLoading) {
      return (
        <LoadingPleaseWait sectionlabel="All Campaigns" pagelabel="View Campaigns"/>
      );
    }
    return (
      <React.Fragment>


        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item h6 text-primary"><Link to="/all-campaigns">All Campaigns</Link></li>
                    <li className="breadcrumb-item"><Link to="#"><i className="mdi mdi-home-outline"></i></Link></li>
                    <li className="breadcrumb-item active">{this.state.campaignname}</li>
                  </ol>
                </Col>
              </Row>
            </div>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      hover
                      striped
                      bordered
                      small
                      data={this.state.data}
                      order={[this.state.sortcolumn, this.state.sort]}
                      onPageChange={e => this.state.page = e.activePage}
                      onSort={e => {
                        this.state.sort = e.direction;
                        this.state.sortcolumn = e.column
                      }}
                      onSearch={e => {
                        this.setState({searchText: e})
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

      </React.Fragment>
    );
  }
}

export default withRouter(connect(null, {activateAuthLayout})(AllCampaigns));



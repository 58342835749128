import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkLogin, clearErrorLogin, clearError } from '../../../store/actions';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import logodark from '../../../images/ips-logo-sails-darker-text-white-128.png';
import { withRouter } from '../../../components/elements/withRouter';
import { Container, Stack, Card, Button, Alert, TextField } from '@mui/material';

class Pageslogin extends Component {

    constructor(props) {
        super(props);
        this.state = {  username : "", password : "" }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event, values) {
        this.props.checkLogin(values.email, values.password, this.props.history);
    }

    componentDidMount()
    {
        document.body.classList.add('bg-primary');
        this.props.clearErrorLogin();
        this.props.clearError();
    }

    render() {
        return (
            <Container>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{m:5, p:5}}
                    >
                        <Card sx={{m:5, p:5}}>
                                                <Link to="/" className="logo"><img src={logodark} height="60" alt="logo" /></Link>
                                <h4 className="font-18 text-center">Login</h4>
                                    <p className="text-muted text-center mb-4">Sign in to continue to ItsPlainSailing Admin Console.</p>

                           {this.props.user && <Alert color="success">
                                    Your Login is successfull.</Alert>}

                            {this.props.loginError && <Alert color="danger">
                                {this.props.loginError}</Alert>}

                                        <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit}>
                                        <AvField style={{marginBottom:"6px", padding: "6px", minWidth: "200px"}} name="email"  value={this.state.username} placeholder="Email" type="text"  validate={{email: true, required: true}}  />
                                        <AvField style={{marginBottom:"6px", padding: "6px", minWidth: "200px"}} name="password"  value={this.state.password} placeholder="Password" type="password" required />

                                        <div className="mt-3">
                                        <Button variant="contained" color="warning" type="submit">Login</Button>
                                        </div>
                                
                                        {/*<div className="mt-4 text-center">*/}
                                        {/*    <Link to="/forget-password"> Forgot your password ?</Link>*/}
                                        {/*</div>*/}
                                    </AvForm>
                        </Card>
                </Stack>
                
            </Container>
        );
    }
}

const mapStatetoProps = state => {
    const { user, loginError, loading } = state.Login;
    return { user, loginError, loading };
}

export default withRouter(connect(mapStatetoProps, { checkLogin, clearErrorLogin, clearError })(Pageslogin));




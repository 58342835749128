import { Card, CardContent, CardHeader, Divider } from "@mui/material";



export function DashCard({ title, children, avatar }) {
    return (
        <Card >
            <CardHeader 
                title={title} 
                avatar={avatar}
                sx={{m:0, p:1}}
            />
            <Divider />
            <CardContent sx={{m:0, p:1}}>
                {children}
            </CardContent>
        </Card>
    )
}
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class SettingMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleExport = this.handleExport.bind(this);
    }

    handleExport = (event) => {
        console.log("change called");
        if (this.props.onExport) {
            this.props.onExport();
        }
        // this.setState({
        //     value: event.target.value
        // });
    }

    render() {

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.setting_menu} toggle={() => this.setState({ setting_menu: !this.state.setting_menu })}>
                    <DropdownToggle color="primary" className="arrow-none waves-effect waves-light">
                        <i className="mdi mdi-settings mr-2"></i> Action
                                        </DropdownToggle>
                    <DropdownMenu className="language-switch">
                        <DropdownItem  onClick={this.handleExport} >Export EMail Addresses</DropdownItem>
                        <div className="dropdown-divider"></div>
                        {/*<DropdownItem tag="a" href="#" onClick={window.open(/api/club/1/updatestripestatus;}>Update Stripe Status</DropdownItem>*/}
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export default SettingMenu;



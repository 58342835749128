// import "@fortawesome/fontawesome-free/css/all.min.css";
// import "bootstrap-css-only/css/bootstrap.min.css";
import React, {Component} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {MDBBtn, MDBDataTable, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBNotification} from 'mdbreact';
import { Grid, Button } from '@mui/material';
import {activateAuthLayout} from '../../../store/actions';
import {connect} from 'react-redux';
import axios from "axios";
import {formatDate, getApiRoot} from "../../../helpers/Utils";
import LoadingPleaseWait from "../Subpages/LoadingPleaseWait";

class SignupRequests extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editmodal: false,
      deletemodal: false,
      selectedoption: -1,
      selectedid: -1,
      isLoading: true,
      page: 1,
      sort: "desc",
      sortcolumn: 'created',
      notification: {
        message: 'message',
        title: 'title',
        text: 'text',
        show: false
      },
      data: {
        columns: [
          {
            label: 'Id',
            field: 'id',
            sort: 'asc',
            width: 20
          }, {
            label: 'Country',
            field: 'country',
            sort: 'asc',
            width: 50
          }, {
            label: 'Created',
            field: 'created',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 80
          },
          {
            label: 'EMail',
            field: 'email',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Organisation',
            field: 'orgaanisation',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Additional Info',
            field: 'additionalinfo',
            sort: 'asc',
            width: 100
          },
          {
            label: '',
            field: 'actionemail',
            width: 100
          },
          {
            label: '',
            field: 'action1',
            width: 100
          },
          {
            label: '',
            field: 'action',
            width: 100
          }
        ],
        rows: []
      }
    };
    console.log("Org constructor");
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.props.activateAuthLayout();
    this.hydrateStateWithLocalStorage();
    this.loadReload();

    window.addEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
  }

  saveStateToLocalStorage() {

    localStorage.setItem('signuppage', this.state.page);
    localStorage.setItem('signupsort', this.state.sort);
    localStorage.setItem('signupsortcolumn', this.state.sortcolumn);
    localStorage.setItem('signupnotification', JSON.stringify(this.state.notification));
  }

  hydrateStateWithLocalStorage() {
    var _this = this;
    this.state.page = this.getStoreValue('signuppage', 1);
    this.state.sort = this.getStoreValue('signupsort', 'desc');
    this.state.sortcolumn = this.getStoreValue('signupsortcolumn', 'created');
    var notification = this.getStoreValue('signupnotification');
    if(notification == null) {
      this.state.notification = {
        text: '',
        title:'',
        message:'',
        show: false
      };
    } else {
      this.state.notification =JSON.parse(notification);
      this.setState({
        notification: this.state.notification
      });
      setTimeout(function() {
        // reset for later
        _this.state.notification = {
          text: '',
          title:'',
          message:'',
          show: false
        };
        localStorage.setItem('signupnotification', JSON.stringify(_this.state.notification ));
      }, 2000);
    }

  }

  getStoreValue(key, default_value) {
    var val = localStorage.getItem(key);
    if (val == null){
      val = default_value;
    }
    return val;
  }

  loadReload() {
    this._asyncRequest = this.load_data().then(
      response => {
        console.log("LoadReload response returned");

        this._asyncRequest = null;
        this.state.data.rows = response;

        console.log("LoadReload updating row status");

        this.setState({
          data: this.state.data,
          isLoading: false
        });
      }
    );

  }

  async load_data() {
    var _this = this;
    console.log("Load data called");
    return axios.get(getApiRoot() + "/api/signup").then(response => {
      console.log("Signup requests fetched");
      if (response.status !== 200)
        throw response.data;

      var rows = [];
      response.data.forEach(function (item, index) {
        var actionemail = "-";
        if(item.status=="incorrect") {
          actionemail = <Button size="small"
                                onClick={(e) => _this.sendEmail(item.id, e)} color="warning">Email</Button>;
        }
        rows.push(
          {
            id: item.id,
            country: item.country,
            status: _this.getSignupStatus(item.status),
            created: formatDate(item.created),
            name: item.name == null || item.name.trim() == '' ? 'Not set' : item.name,
            email: item.email == null || item.email.trim() == '' ? 'Not set' : item.email,
            orgaanisation: item.orgaanisation == null || item.orgaanisation.trim() == '' ? 'Not set' : item.orgaanisation,
            additionalinfo: item.additionalinfo + " - (Category: " + item.status + ")",
            action: <Button size="small"
                            onClick={(e) => _this.deleteRow(item.id, e)} color="error">Delete</Button>,
            action1: <Button size="small"
                             onClick={(e) => _this.editRow(item.id, e)} color="success">Edit</Button>,
            actionemail: actionemail
          }
        )
        ;
      });
      return rows;
    }).catch(err => {
      throw err[1];
    });


  }


  deleteRow(id, event) {
    // this.setState(state => ({
    //   isToggleOn: !state.isToggleOn
    // }));
    this.state.selectedid = id;
    console.log("Delete row: " + id);
    this.showDeleteModal(true);
  }

  editRow(id, event) {
    // this.setState(state => ({
    //   isToggleOn: !state.isToggleOn
    // }));
    this.state.selectedid = id;
    console.log("Edit row: " + id);
    this.showEditModal(true);

  }

  sendEmail(id, event) {
    this.state.selectedid = id;
    console.log("Send Email: " + id);
    // this.showEditModal(true);

  }

  toggle = nr => () => {
    let modal = nr + 'modal'
    this.setState({
      [modal]: !this.state[modal]
    });
  }

  showDeleteModal(yes) {
    this.state.deletemodal = yes;
    this.setState({
      deletemodal: this.state.deletemodal
    });
  }

  showEditModal(yes) {
    this.state.editmodal = yes;
    this.setState({
      editmodal: this.state.editmodal
    });
  }

  confirmDelete() {
    var _this = this;
    axios.delete("/api/signup/" + this.state.selectedid).then(response => {
      if (response.status !== 200)
        throw response.data;

      _this.shownotify("Operation Complete", 'Now', 'Signup request removed from the database', false);
      // this.loadReload();
      _this.showDeleteModal(false);
      window.location.reload();

    });
  }

  shownotify (title, text, message, now=true) {

    this.state.notification.title = title;
    this.state.notification.text = text;
    this.state.notification.message = message;
    this.state.notification.show = true;
    if(now) {
      this.setState({
        notification: this.state.notification
      });
    } else {
      this.saveStateToLocalStorage();
    }
  }

  confirmSave() {
    if(this.state.selectedoption == -1) {
      this.shownotify("Cannot complete", "", "Please select a new status to use", true);
      return;
    }
    var _this = this;
    // axios.put("/api/signup/" + this.state.selectedid, {
    //   status: this.state.selectedoption
    // }).then(response => {
    //   if (response.status !== 200)
    //     throw response.data;
    //
    //   // this.loadReload();
    //   _this.showEditModal(false);
    //
    // });
    console.log("Setting status to " + this.state.selectedoption);
    axios.put("/api/signup/" + this.state.selectedid, 'status=' + this.state.selectedoption).then(response => {
      if (response.status !== 200)
        throw response.data;

      // this.loadReload();
      _this.showEditModal(false);
      // window.location.reload();

    });

  }

  getSignupStatus(status) {

    if (status == 'test') {
      return <span className="badge badge-pill badge-dark">Test</span>;
    }

    if (status == 'incorrect') {
      return <span className="badge badge-pill badge-danger">Incorrect</span>;
    }

    if (status == 'special') {
      return <span className="badge badge-pill badge-primary">Special</span>;
    }

    if (status == 'not created') {
      return <span className="badge badge-pill badge-warning">Not Created</span>;
    }

    if (status == 'created') {
      return <span className="badge badge-pill badge-success">Created</span>;
    }

    if (status == 'received') {
      return <span className="badge badge-pill badge-info">Received</span>;
    }

    return status;
  }

  render() {
    if (this.state.isLoading) {
      return (
        <LoadingPleaseWait sectionlabel="Sign Up Requests" pagelabel="Manage Orgs"/>
      );
    }
    return (
      <Grid container spacing={1}>
        <Grid items xs={12}>

                <h5>Signup Requests</h5>                
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      hover
                      striped
                      bordered
                      small
                      data={this.state.data}
                      order={[this.state.sortcolumn, this.state.sort]}
                      onPageChange={e=>this.state.page=e.activePage}
                      onSort={e=> {this.state.sort=e.direction; this.state.sortcolumn = e.column}}
                    />
                  </CardBody>
                </Card>
              </Grid>
          <MDBModal isOpen={this.state.deletemodal}>
            <MDBModalHeader toggle={this.toggle('delete')}>Delete This Signup Request</MDBModalHeader>
            <MDBModalBody>
              Please click "Yes Delete" below to confirm the deleting of the signup request with
              id {this.state.selectedid}, otherwise click Cancel
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={(e) => {
                this.showDeleteModal(false)
              }}>Cancel</MDBBtn>
              <MDBBtn color="success" onClick={(e) => {
                this.confirmDelete()
              }}>Yes Delete</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.editmodal} fullHeight position="right">
            <MDBModalHeader toggle={this.toggle('edit')}>Update Status</MDBModalHeader>
            <MDBModalBody>
              Select the new status to assign to this signup request from the list below
              <select className="browser-default custom-select"
                      onChange={(e)=> { console.log(e.target.value); this.state.selectedoption = e.target.value; }}
              >
                <option>Select New Status</option>
                <option value="created">Created</option>
                <option value="not created">Not Created</option>
                <option value="incorrect">Incorrect</option>
                <option value="special">Special</option>
                <option value="test">Test</option>
              </select>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={(e) => {
                this.showEditModal(false)
              }}>Cancel</MDBBtn>
              <MDBBtn color="success" onClick={(e) => {
                this.confirmSave()
              }}>Save</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBNotification
            autohide={5000} // by default = ∞ ms
            fade
            show={this.state.notification.show}
            icon="bell"
            iconClassName="text-success"
            message={this.state.notification.message}
            text={this.state.notification.text}
            title={this.state.notification.title}
            style={{
              position: "fixed",
              top: "10px",
              right: "10px",
              zIndex: 9999
            }}
          />
        </Grid>

    );
  }
}

export default connect(null, {activateAuthLayout})(SignupRequests);



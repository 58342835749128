import axios from "axios";


function getApiRoot() {
  var env       = process.env.REACT_APP_NODE_ENV || 'development';
  var apiroot= '/api/';

  console.debug("REACT_APP_NODE_ENV: " + env);
  if (env === 'development') {
    apiroot= 'http://localhost:3010';
  } 
  if (env === 'staging') {
    apiroot= 'https://adminapi.entries.club';
  } 
  if (env === 'production') {
    apiroot= 'https://adminapi.itsplainsailing.com';
  } 

  return apiroot;
}


//Set the logged in user data in local session 
function setLoggeedInUser (user) {
  axios.defaults.headers.common['x-access-token'] = user.token;
  localStorage.setItem('user', JSON.stringify(user));
}

// Gets the logged in user data from local session 
function getLoggedInUser() {
  const user = localStorage.getItem('user');
  if (user)
      return JSON.parse(user);
  return null;
}

//is user is logged in
function isUserAuthenticated() {
  return getLoggedInUser() !== null;
}


function getSubscriptionType(subscriptiontype) {
  switch (subscriptiontype) {
    case 4:
    case 15:
    case 14:
      return "Yearly Fee";
    default:
      return "Per Transaction"
  }

}


function getClubNameById(clubs, id) {
  return clubs.find(x => x.id === id);
}

function formatCurrency(value, currency="EUR") {
  const formatter = new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits:0,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(value);
}

function formatDate(datestr) {
  if(datestr == null) {
    return '0';
  }
  var d1 = new Date(datestr);
  var newdate = d1.getFullYear() + '-' + (d1.getMonth() + 1).toString().padStart(2, '0') + "-" + d1.getDate().toString().padStart(2, '0');
  // console.log("Returning date: " + newdate);
  return newdate;
}

function formatDateTime(datetimestr) {
  if(datetimestr == null) {
    return '0';
  }
  var d1 = new Date(datetimestr);
  var newdatetime = d1.getFullYear() + '-' + (d1.getMonth() + 1).toString().padStart(2, '0') + "-" + d1.getDate().toString().padStart(2, '0')
    + " " + d1.getHours() + ":" + d1.getMinutes().toString().padStart(2, '0') + ":" + d1.getSeconds().toString().padStart(2, '0');
  // console.log("Returning date: " + newdate);
  return newdatetime;
}

function getStatus(status) {
  var statii = [
    'Not Activated',
    'T&C',
    'Stripe',
    'Activated'
  ];
  return statii[status];
}

function isSubstringIgnoreCase(haystack, needle) {
  if(haystack == null || haystack == "") {
    return false;
  }
  // console.log("Searching for [" + needle + "] in ["+haystack+"]");
  return haystack.toLowerCase().indexOf(needle) !== -1;
}

export { formatCurrency, getSubscriptionType, formatDate, getStatus, isSubstringIgnoreCase, getApiRoot, formatDateTime, setLoggeedInUser, getLoggedInUser, isUserAuthenticated }

import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Button } from '@mui/material';
import { MDBDataTable } from 'mdbreact';
import { activateAuthLayout } from '../../../store/actions';
import { connect } from 'react-redux';
import Settingmenu from '../Subpages/Settingmenu';
import LoadingPleaseWait from '../Subpages/LoadingPleaseWait';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import axios from "axios";
import { formatDate, getApiRoot, getStatus, getSubscriptionType, isSubstringIgnoreCase } from "../../../helpers/Utils";
import { withRouter } from '../../../components/elements/withRouter';

class Allorgs extends Component {

  constructor(props) {
    super(props);
    this.handleExport = this.handleExport.bind(this);

    this.state = {
      searchText: "",
      isLoading: true,
      page: 1,
      sort: "asc",
      sortcolumn: 'last12monthsapplicationfee',
      data: {
        columns: [
          {
            label: 'Id',
            field: 'id',
            sort: 'asc',
            width: 20
          }, {
            label: 'Shortcode',
            field: 'shortcode',
            sort: 'asc',
            width: 50
          }, {
            label: 'Name',
            field: 'clubname',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Type',
            field: 'type',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Country',
            field: 'country',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Stripe Status',
            field: 'stripestatus',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Created',
            field: 'accountcreated',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Activated',
            field: 'accountactivated',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Invoice Due',
            field: 'invoiceduedate',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Payments(Last 12 Mon)',
            field: 'last12monthstotalvalue',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Revenue (Last 12 Mon)',
            field: 'last12monthsapplicationfee',
            sort: 'desc',
            width: 100
          },
          {
            label: 'Last Payment Made',
            field: 'lastorderdate',
            sort: 'asc',
            width: 100
          },
          {
            label: '',
            field: 'action',
            width: 100
          }
        ],
        rows: []
      }
    };
    console.log("Org constructor");
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.props.activateAuthLayout();
    this.hydrateStateWithLocalStorage();


    this._asyncRequest = this.load_data().then(
      response => {
        this._asyncRequest = null;
        this.state.data.rows = response;
        this.setState({
          data: this.state.data,
          isLoading: false
        });
      }
    );

    window.addEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this),
    );
  }

  handleExport(e) {
    var _this = this;
    console.log("Export Called using search string " + this.state.searchText);
    this.state.searchText = this.state.searchText.toLowerCase();
    // go through the set of clubs and filter them against the search string
    var clubs = this.state.data.rows.filter(function (club) {
      if (_this.state.searchText == "") {
        return true;
      }
      var res = isSubstringIgnoreCase(club.shortcode, _this.state.searchText) ||
        isSubstringIgnoreCase(club.clubname, _this.state.searchText) ||
        isSubstringIgnoreCase(club.status, _this.state.searchText) ||
        isSubstringIgnoreCase(club.type, _this.state.searchText) ||
        isSubstringIgnoreCase(club.stripestatus, _this.state.searchText) ||
        isSubstringIgnoreCase(club.country, _this.state.searchText) ||
        isSubstringIgnoreCase(club.accountcreated, _this.state.searchText) ||
        isSubstringIgnoreCase(club.accountactivated, _this.state.searchText) ||
        isSubstringIgnoreCase(club.invoiceduedate, _this.state.searchText) ||
        isSubstringIgnoreCase(club.last12monthstotalvalue.toString(), _this.state.searchText) ||
        isSubstringIgnoreCase(club.id.toString(), _this.state.searchText) ||
        isSubstringIgnoreCase(club.last12monthsapplicationfee.toString(), _this.state.searchText) ||
        isSubstringIgnoreCase(club.lastorderdate, _this.state.searchText);

      // console.log("Searching result matched = [" + (res ? "Yes" : "No"));
      return res;
    });

    var clubids = clubs.map(c => {
      return {
        id: c.id,
        clubname: c.clubname
      }
    }
    );

    this.props.history.push({
      pathname: '/view-emails',
      state: clubids
    })
    // return false;
  }

  saveStateToLocalStorage() {
    localStorage.setItem('page', this.state.page);
    localStorage.setItem('sort', this.state.sort);
    localStorage.setItem('sortcolumn', this.state.sortcolumn);
  }

  hydrateStateWithLocalStorage() {
    this.state.page = this.getStoreValue('page', 1);
    this.state.sort = this.getStoreValue('sort', 'desc');
    this.state.sortcolumn = this.getStoreValue('sortcolumn', 'last12monthsapplicationfee');

  }

  getStoreValue(key, default_value) {
    var val = localStorage.getItem(key);
    if (val == null) {
      val = default_value;
    }
    return val;
  }


  async load_data() {
    var _this = this;
    let clubtype = new URLSearchParams(this.props.location.search).get("clubtype");
    return axios.get(getApiRoot() + "/api/club"+(clubtype ? `?clubtype=${clubtype}` : "")).then(response => {
      if (response.status !== 200)
        throw response.data;

      console.log("Fetched " + response.data.length);
      var allclubs = response.data;
      var rows = [];
      allclubs.forEach(function (item, index) {
        // console.log("PRocessing club");
        // console.log(item);
        var stype = getSubscriptionType(item.subscriptiontype);
        // console.log("Returned sub type: " + stype);
        var linkpath = "/view-org/" + item.id;
        rows.push({
          id: item.id,
          shortcode: item.shortcode,
          clubname: item.clubname,
          stripestatus: item.stripestatus == '' ? 'N/A' : item.stripestatus,
          status: getStatus(item.status),
          type: stype,
          country: item.clubtype == 15 ? 'UK' : 'IE',
          accountcreated: formatDate(item.accountcreated),
          accountactivated: item.status == 3 ? formatDate(item.accountcreated) : "N/A",
          invoiceduedate: stype == "Yearly Fee" ? formatDate(item.invoiceduedate) : 'N/A',
          last12monthstotalvalue: item.last12monthstotalvalue == 0 ? '0' : item.last12monthstotalvalue,
          last12monthsapplicationfee: item.last12monthsapplicationfee == 0 ? '0' : item.last12monthsapplicationfee,
          lastorderdate: formatDate(item.lastorderdate),
          action: <Link to={linkpath} color="primary">
            <Button >View</Button></Link>
        });

      });
      console.log(rows);
      return rows;

    }).catch(err => {
      throw err[1];
    });


  }


  render() {
    if (this.state.isLoading) {
      return (
        <LoadingPleaseWait sectionlabel="All Organisations" pagelabel="Manage Orgs" />
      );
    }
    return (
      <Grid container spacing={1}>
        <h5>All Organisations</h5>

        <Grid item xs={12}>
          <Settingmenu onExport={this.handleExport} />
        </Grid>
        <Grid item xs={12}>

          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                hover
                striped
                bordered
                small
                data={this.state.data}
                order={[this.state.sortcolumn, this.state.sort]}
                onPageChange={e => this.setState({
                  ...this.state,
                  page: e.activePage
                })}
                onSort={e => {
                  this.setState({
                    ...this.state,
                    sort: e.direction,
                    sortcolumn: e.column
                  })
                  // this.state.sort = e.direction;
                  // this.state.sortcolumn = e.column
                }}
                onSearch={e => {
                  this.setState({ searchText: e })
                }}
              />
            </CardBody>
          </Card>
        </Grid>

      </Grid>
    );
  }
}

export default withRouter(connect(null, { activateAuthLayout })(Allorgs));



import flattenDeep from 'lodash/flattenDeep';
import React from 'react';
import { Route, Routes as ReactRoutes, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

const generateFlattenRoutes = (routes) => {
    if (!routes) return [];
    return flattenDeep(routes.map(({ routes: subRoutes, ...rest }) => [rest, generateFlattenRoutes(subRoutes)]));
  }

export const renderRoutes = (mainRoutes) => {
const Routes = ({ isAuthorized }) => {
    const layouts = mainRoutes.map(({ layout: Layout, routes, isPublic }, index) => {
    const subRoutes = generateFlattenRoutes(routes);

    return (
        <Route key={index} element={<Layout />}>
        <Route element={<ProtectedRoute  isAuthorized={isAuthorized} isPublic={isPublic}/>}>
            {subRoutes.map(({ component, path, name }) => {
                return (
                    path && (<Route key={name} element={component} path={path} />)
                )
            })}
        </Route>
        <Route path="*" element={<Navigate to="/home" />} />
        </Route>
    )
    });
    return <ReactRoutes>{layouts}</ReactRoutes>;
}
return Routes;
}
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { activateAuthLayout } from '../../../store/actions';
import { connect } from 'react-redux';
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { getApiRoot } from "../../../helpers/Utils"
import { withRouter } from '../../../components/elements/withRouter';
import { Grid, Avatar } from '@mui/material';
import { DashCard } from '../../../components/elements/DashCard';
import { red, green } from '@mui/material/colors';
import ValueLabel from '../../../components/elements/ValueLabel';

// import Apexbar from "../../charts/apex/apexbar";

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1', activeother: '1', startDate: new Date(),
      clubstats: {
        total: 0,
        activetotal: 0,
        annual: 0,
        pertransaction: 0,
        irishactive: 0,
        ukactive: 0,
        signeduplast30days: 0,
        signeduplast12months: 0,
        activatedlast30days: 0,
        activatedlast12months: 0,
        invoicesoverdue: 0,
        invoicesdue: 0
      },
      userstats: {
        activeusers: 0,
        blockedusers: 0,
        newlast30days: 0,
        loggedinlast30days: 0,
        totalusers30days: 0
      },
      paymentstats: [
        {
          date: "",
          overall_total: 0,
          card_total: 0,
          cheque_total: 0,
          num_transactions: 0,
          applicationfee: 0,
          num_payments: 0
        },
        {
          date: "",
          overall_total: 0,
          card_total: 0,
          cheque_total: 0,
          num_transactions: 0,
          applicationfee: 0,
          num_payments: 0
        }
      ],
      revenuestats: [
        {
          total: 0,
          yearlyfee: 0,
          applicationfee: 0
        },
        {
          total: 0,
          yearlyfee: 0,
          applicationfee: 0
        }

      ],
      transactionstats: [
        {
          date: "",
          event: {
            number: 0,
            value: 0
          },
          merchandise: {
            number: 0,
            value: 0
          },
          calendar: {
            number: 0,
            value: 0
          },
          membership: {
            number: 0,
            value: 0
          }
        }
      ],
      comparisons: [
        {
          name: 'Total Value Paid',
          lastyear: 0,
          thisyear: 0,
          increase: true,
          percentage: 0
        }, {
          name: 'Card  Payments',
          lastyear: 0,
          thisyear: 0,
          increase: true,
          percentage: 0
        }, {
          name: 'Offline Payments',
          lastyear: 0,
          thisyear: 0,
          increase: true,
          percentage: 0
        }, {
          name: 'Num Payments',
          lastyear: 0,
          thisyear: 0,
          increase: true,
          percentage: 0
        }, {
          name: 'Num Transactions',
          lastyear: 0,
          thisyear: 0,
          increase: true,
          percentage: 0
        }, {
          name: 'Application Fee',
          lastyear: 0,
          thisyear: 0,
          increase: true,
          percentage: 0
        }
      ],
      comparisonlabel: "Loading...",
      chart: [
        {
          options: {
            chart: {
              type: 'area',
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth',
              width: 2
            },
            colors: ['orange', '#4090cb', '#e74c5e'],
            xaxis: {
              type: 'datetime'
            },
            yaxis: {
              title: {
                text: 'euro'
              }
            },
            grid: {
              yaxis: {
                lines: {
                  show: false,
                }
              }
            }
          },
          series: []
        },
        {
          options: {
            chart: {
              type: 'area',
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth',
              width: 2
            },
            colors: ['orange'],
            xaxis: {
              type: 'datetime'
            },
            yaxis: {
              title: {
                text: 'euro'
              }
            },
            grid: {
              yaxis: {
                lines: {
                  show: false,
                }
              }
            },
          },
          series: []
        },
        {
          options: {
            chart: {
              height: 280,
              type: 'pie',
            },
            labels: ["Event", "Membership", "Merchandise", "Calendar"],
            colors: ["#47bd9a", "#4090cb", "#e74c5e", "#f9d570"],
            legend: {
              show: true,
              position: 'bottom',
              horizontalAlign: 'center',
              verticalAlign: 'middle',
              floating: false,
              fontSize: '14px',
              offsetX: 0,
              offsetY: -10
            },
            responsive: [{
              breakpoint: 600,
              options: {
                chart: {
                  height: 240
                },
                legend: {
                  show: false
                },
              }
            }]
          },
          series1: [0, 0, 0, 0],
          series2: [0, 0, 0, 0]

        },
        {
          options: {
            chart: {
              type: 'area',
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth',
              width: 2
            },
            colors: ['orange', '#4090cb', '#e74c5e', "#3CB371"],
            xaxis: {
              type: 'datetime'
            },
            yaxis: {
              title: {
                text: 'Transactions'
              }
            },
            grid: {
              yaxis: {
                lines: {
                  show: true,
                }
              }
            }
          },
          series: []
        },
        {
          options: {
            chart: {
              type: 'area',
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth',
              width: 2
            },
            colors: ['orange', '#4090cb', '#e74c5e', '#3CB371'],
            xaxis: {
              type: 'datetime'
            },
            yaxis: {
              title: {
                text: 'Euro'
              }
            },
            grid: {
              yaxis: {
                lines: {
                  show: true,
                }
              }
            }
          },
          series: []
        },
        {
          options: {
            chart: {
              type: 'line',
              height: 60,
              sparkline: {
                enabled: true
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth',
              width: 3
            },
            colors: ['lightgreen', '#4090cb', '#e74c5e', "#f9d570"],
            marker: {
              show: false
            },
            tooltip: {
              fixed: {
                enabled: false
              },
              x: {
                show: true
              },
              y: {
                title: {
                  formatter: function (seriesName) {
                    return ''
                  }
                }
              },
              marker: {
                show: false
              }
            }
          },
          series: {
            event: [],
            calendar: [],
            membership: [],
            merchandise: []
          }
        }]
    };

    this
      .toggleStock = this.toggleStock.bind(this);
    this
      .toggleMessages = this.toggleMessages.bind(this);
  }

  getOptions(options, colors) {
    let cloneOptions = JSON.parse(JSON.stringify(options));
    cloneOptions.colors = colors;
    return cloneOptions;
  }

  componentDidMount() {
    this.props.activateAuthLayout();

    document.body.classList = "";
    if (this.props.location.pathname === '/layout-light') {
      document.body.classList.add('left-side-menu-light');
    } else if (this.props.location.pathname === '/layout-collapsed') {
      document.body.classList.toggle('enlarged');
    } else {
    }

    var _this = this;
    setTimeout(function () {
      _this.fetchMetrics();
    }, 2000);

  }

  fetchMetrics() {
    this.fetchClubMetrics();
  }

  sameMonth(d1, d2) {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    return date1.getMonth() == date2.getMonth() && date1.getFullYear() == date2.getFullYear();
  }

  resetMonth(stat) {
    stat.data = stat.date.substr(0, 8) + "01";
    return stat;
  }

  convertToMonthly(stats) {
    let monthStats = [];
    monthStats.push(this.resetMonth(JSON.parse(JSON.stringify(stats[0]))))
    for (let i = 1; i < stats.length; i++) {
      if (this.sameMonth(monthStats[monthStats.length - 1].date, stats[i].date)) {
        monthStats[monthStats.length - 1].applicationfee += stats[i].applicationfee;
        monthStats[monthStats.length - 1].card_total += stats[i].card_total;
        monthStats[monthStats.length - 1].cheque_total += stats[i].cheque_total;
        monthStats[monthStats.length - 1].num_payments += stats[i].num_payments;
        monthStats[monthStats.length - 1].num_transactions += stats[i].num_transactions;
        monthStats[monthStats.length - 1].overall_total += stats[i].overall_total;
      } else {
        monthStats.push(this.resetMonth(JSON.parse(JSON.stringify(stats[i]))));
      }
    }
    return monthStats;
  }

  convertTransactionToMonthly(stats) {
    let monthStats = [];
    monthStats.push(this.resetMonth(JSON.parse(JSON.stringify(stats[0]))))
    for (let i = 1; i < stats.length; i++) {
      if (this.sameMonth(monthStats[monthStats.length - 1].date, stats[i].date)) {
        monthStats[monthStats.length - 1].event.number += stats[i].event.number;
        monthStats[monthStats.length - 1].event.value += stats[i].event.value;
        monthStats[monthStats.length - 1].membership.number += stats[i].membership.number;
        monthStats[monthStats.length - 1].membership.value += stats[i].membership.value;
        monthStats[monthStats.length - 1].merchandise.number += stats[i].merchandise.number;
        monthStats[monthStats.length - 1].merchandise.value += stats[i].merchandise.value;
        monthStats[monthStats.length - 1].calendar.number += stats[i].calendar.number;
        monthStats[monthStats.length - 1].calendar.value += stats[i].calendar.value;
      } else {
        monthStats.push(this.resetMonth(JSON.parse(JSON.stringify(stats[i]))));
      }
    }
    return monthStats;
  }

  fetchClubMetrics() {
    var _this = this;
    axios.get(getApiRoot() + "/api/metrics?type=club").then(response => {
      if (response.status !== 200)
        throw response.data;

      // console.log("Fetched metrics ");
      // console.log(response.data);
      this.setState({ clubstats: response.data });

      axios.get(getApiRoot() + "/api/metrics?type=user").then(response => {
        if (response.status !== 200)
          throw response.data;

        // console.log("Fetched metrics ");
        // console.log(response.data);
        this.setState({ userstats: response.data });

        axios.get(getApiRoot() + "/api/metrics?type=payments").then(response => {
          if (response.status !== 200)
            throw response.data;

          // console.log("Fetched metrics ");
          // console.log(response.data);
          let monthlyStats = this.convertToMonthly(response.data);
          this.setState({ paymentstats: monthlyStats });

          this.updateComparisonStats(monthlyStats);

          this.updateChart(response.data);

          axios.get(getApiRoot() + "/api/metrics?type=transaction").then(response => {
            if (response.status !== 200)
              throw response.data;

            this.setState({ transactionstats: this.convertTransactionToMonthly(response.data) });

            this.updateTransactionCharts(response.data);

            this.updateRevenueStats();


          }).catch(err => {
            throw err[1];
          });
        }).catch(err => {
          throw err[1];
        });
      }).catch(err => {
        throw err[1];
      });
    }).catch(err => {
      throw err[1];
    });
  }

  updateRevenueStats() {
    var thisyear = new Date().getFullYear();
    this.state.revenuestats[0].yearlyfee = this.calculateYearlyFee(this.state.clubstats.annual);
    this.state.revenuestats[0].applicationfee = this.getApplicationFees(this.state.paymentstats, thisyear);
    this.state.revenuestats[0].total = this.state.revenuestats[0].applicationfee + this.state.revenuestats[0].yearlyfee;
    this.state.revenuestats[1].yearlyfee = this.calculateYearlyFee(this.state.clubstats.annual - this.state.clubstats.annualthisyear);
    this.state.revenuestats[1].applicationfee = this.getApplicationFees(this.state.paymentstats, thisyear - 1);;
    this.state.revenuestats[1].total = this.state.revenuestats[1].applicationfee + this.state.revenuestats[1].yearlyfee;
    this.setState({
      revenuestats: this.state.revenuestats
    });
  }

  calculateYearlyFee(numyears) {
    return numyears * 120;
  }

  updateChart(paymentstats) {
    this.state.chart[0].series = this.getPaymentSeries(paymentstats);
    this.state.chart[1].series = this.getApplicationSeries(paymentstats);
    this.setState({ chart: this.state.chart });

  }

  updateTransactionCharts(stats) {
    this.state.chart[3].series = this.getTransactionNumberSeries(stats);
    this.state.chart[4].series = this.getTransactionValueSeries(stats);

    this.state.chart[5].series.event = [{
      name: 'Events',
      data: this.state.chart[4].series[0].data.splice(this.state.chart[4].series[0].data.length - 13, this.state.chart[4].series[0].data.length - 1)
    }];
    this.state.chart[5].series.membership = [{
      name: 'Membership',
      data: this.state.chart[4].series[1].data.splice(this.state.chart[4].series[1].data.length - 13, this.state.chart[4].series[1].data.length - 1)
    }];
    this.state.chart[5].series.merchandise = [{
      name: 'Merchandise',
      data: this.state.chart[4].series[2].data.splice(this.state.chart[4].series[2].data.length - 13, this.state.chart[4].series[2].data.length - 1)
    }];
    this.state.chart[5].series.calendar = [{
      name: 'Calendar',
      data: this.state.chart[4].series[3].data.splice(this.state.chart[4].series[3].data.length - 13, this.state.chart[4].series[3].data.length - 1)
    }];
    // console.log(this.state.chart[5].series.event);

    this.state.chart[4].series = this.getTransactionValueSeries(stats);

    this.updateTransactionPieCharts(stats);
  }

  // updateTransactionCharts(stats) {
  //   this.state.chart[3].series = this.getTransactionNumberSeries(stats);
  //   this.state.chart[4].series = this.getTransactionValueSeries(stats);
  //
  //   var seriescopy = JSON.parse(JSON.stringify(this.state.chart[4].series));
  //   this.state.chart[5].series.event = [{
  //     name: 'Events',
  //     data: seriescopy[0].data.splice(seriescopy[0].data.length - 13, seriescopy[0].data.length - 1)
  //   }];
  //   this.state.chart[5].series.membership = [{
  //     name: 'Membership',
  //     data: seriescopy[1].data.splice(seriescopy[1].data.length - 13, seriescopy[4].series[1].data.length - 1)
  //   }];
  //   this.state.chart[5].series.merchandise = [{
  //     name: 'Merchandise',
  //     data: seriescopy[2].data.splice(seriescopy[2].data.length - 13, seriescopy[2].data.length - 1)
  //   }];
  //   this.state.chart[5].series.calendar = [{
  //     name: 'Calendar',
  //     data: seriescopy[3].data.splice(seriescopy[3].data.length - 13, seriescopy[3].data.length - 1)
  //   }];
  //   // console.log(this.state.chart[5].series.event);
  //   this.updateTransactionPieCharts(stats);
  // }

  updateTransactionPieCharts(stats) {
    var thisyearseries = this.sumYear(stats, new Date().getFullYear());
    var lastyearseries = this.sumYear(stats, new Date().getFullYear() - 1);
    this.state.chart[2].series1 = thisyearseries;
    this.state.chart[2].series2 = lastyearseries;
    this.setState({ chart: this.state.chart });
  }

  sumYear(stats, year) {
    var events = 0;
    var merchandise = 0;
    var calendar = 0;
    var membership = 0;
    stats.forEach(function (item, index) {
      var d1 = new Date(item.date.toString());
      if (year == d1.getFullYear()) {
        events += item.event.number;
        merchandise += item.merchandise.number;
        calendar += item.calendar.number;
        membership += item.membership.number;
      }
    });

    return [events, membership, merchandise, calendar];
  }

  getApplicationSeries(paymentstats) {
    var applicationfee = [];
    paymentstats.forEach(function (item, index) {
      applicationfee.push({
        x: item.date,
        y: item.applicationfee
      });
    });
    return [{
      name: 'Application Fees',
      data: applicationfee
    }]
  }

  getApplicationFees(paymentstats, year) {
    var applicationfee = 0;
    paymentstats.forEach(function (item, index) {
      var thisyear = new Date(item.date).getFullYear();
      if (year == thisyear) {
        applicationfee += item.applicationfee;
      }
    });
    return applicationfee;
  }

  getPaymentSeries(paymentstats) {
    var overall = [];
    var card = [];
    var offline = [];
    paymentstats.forEach(function (item, index) {
      overall.push({
        x: item.date,
        y: item.overall_total
      });
      card.push({
        x: item.date,
        y: item.card_total
      });
      offline.push({
        x: item.date,
        y: item.cheque_total
      });
    });
    return [{
      name: 'Overall',
      data: overall
    }, {
      name: 'Card',
      data: card
    }, {
      name: 'Cheque/ Offline',
      data: offline
    }]
  }

  getTransactionNumberSeries(transactionstats) {
    var events = [];
    var merchandise = [];
    var calendar = [];
    var membership = [];
    transactionstats.forEach(function (item, index) {
      events.push({
        x: item.date,
        y: item.event.number
      });
      merchandise.push({
        x: item.date,
        y: item.merchandise.number
      });
      calendar.push({
        x: item.date,
        y: item.calendar.number
      });
      membership.push({
        x: item.date,
        y: item.membership.number
      });
    });
    return [{
      name: 'Event Entries',
      data: events
    }, {
      name: 'Membership',
      data: membership
    }, {
      name: 'Merchandise',
      data: merchandise
    }, {
      name: 'Calendar',
      data: calendar
    }]
  }

  getTransactionValueSeries(transactionstats) {
    var events = [];
    var merchandise = [];
    var calendar = [];
    var membership = [];
    transactionstats.forEach(function (item, index) {
      events.push({
        x: item.date,
        y: item.event.value
      });
      merchandise.push({
        x: item.date,
        y: item.merchandise.value
      });
      calendar.push({
        x: item.date,
        y: item.calendar.value
      });
      membership.push({
        x: item.date,
        y: item.membership.value
      });
    });
    return [{
      name: 'Event Entries',
      data: events
    }, {
      name: 'Membership',
      data: membership
    }, {
      name: 'Merchandise',
      data: merchandise
    }, {
      name: 'Calendar',
      data: calendar
    }]
  }


  updateComparisonStats(paymentstats) {
    console.log("updating comparison stats");
    var lastmonth = paymentstats[paymentstats.length - 2];
    var lastyear = paymentstats[paymentstats.length - 14];

    var currentcomparisons = [
      {
        name: 'Total Value Paid',
        lastyear: 0,
        thisyear: 0,
        increase: true,
        percentage: 0
      }, {
        name: 'Card  Payments',
        lastyear: 0,
        thisyear: 0,
        increase: true,
        percentage: 0
      }, {
        name: 'Offline Payments',
        lastyear: 0,
        thisyear: 0,
        increase: true,
        percentage: 0
      }, {
        name: 'Num Payments',
        lastyear: 0,
        thisyear: 0,
        increase: true,
        percentage: 0
      }, {
        name: 'Num Transactions',
        lastyear: 0,
        thisyear: 0,
        increase: true,
        percentage: 0
      }, {
        name: 'Application Fee',
        lastyear: 0,
        thisyear: 0,
        increase: true,
        percentage: 0
      }
    ];

    currentcomparisons[0] = this.applyValues(currentcomparisons[0], lastyear.overall_total, lastmonth.overall_total);
    currentcomparisons[1] = this.applyValues(currentcomparisons[1], lastyear.card_total, lastmonth.card_total);
    currentcomparisons[2] = this.applyValues(currentcomparisons[2], lastyear.cheque_total, lastmonth.cheque_total);
    currentcomparisons[3] = this.applyValues(currentcomparisons[3], lastyear.num_payments, lastmonth.num_payments);
    currentcomparisons[4] = this.applyValues(currentcomparisons[4], lastyear.num_transactions, lastmonth.num_transactions);
    currentcomparisons[5] = this.applyValues(currentcomparisons[5], lastyear.applicationfee, lastmonth.applicationfee);

    console.log(currentcomparisons);
    this.setState({ comparisonlabel: this.getComparisonLabel(lastmonth.date, lastyear.date) });
    this.setState({ comparisons: currentcomparisons });

  }

  getComparisonLabel(thismonth, lastyear) {
    var d1 = new Date(thismonth);
    var d2 = new Date(lastyear);
    var options = { month: 'long', year: 'numeric' };
    return d2.toLocaleDateString(undefined, options) + " Vs " + d1.toLocaleDateString(undefined, options);
  }

  applyValues(entry, lastyear, thisyear) {
    entry.increase = thisyear > lastyear;
    entry.thisyear = thisyear;
    entry.lastyear = lastyear;
    if (entry.increase) {
      entry.percentage = Math.round(((thisyear - lastyear) / lastyear) * 100);
    } else {
      entry.percentage = Math.round(((lastyear - thisyear) / lastyear) * 100);
    }
    return entry;
  }

  toggleStock(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  toggleMessages(tab) {
    if (this.state.activeother !== tab) {
      this.setState({
        activeother: tab
      });
    }
  }

  getColor(increase) {
    if (increase) {
      return green[500];
    }
    return red[500];
  }

  render() {

    return (
        <Grid container spacing={1}>
          <Grid item md={3}>
            <DashCard
              title={this.state.comparisonlabel}
              avatar={<Avatar sx={{ bgcolor: this.getColor(this.state.comparisons[0].increase) }} aria-label="value change">{(this.state.comparisons[0].increase ? '' : '-') + this.state.comparisons[0].percentage + "%"}</Avatar>}
            >
              {this.state.comparisons.map((row, iindex) => {
                var currency = row.name[0] == 'N' ? '' : '€';
                if (row.increase) {
                  return (
                    <ValueLabel value={`${currency}${row.lastyear.toLocaleString()} Vs. ${currency}${row.thisyear.toLocaleString()} 
                          ^ ${row.percentage}% `} label={row.name} />
                  )
                } else {
                  return (
                    <ValueLabel value={`${currency}${row.lastyear.toLocaleString()} Vs. ${currency}${row.thisyear.toLocaleString()} 
                          v -${row.percentage}% `} label={row.name} />
                  )
                }
              })}
            </DashCard>
          </Grid>

          <Grid item md={3}>
            <DashCard
              title="Active Organisations"
              avatar={<Avatar sx={{ bgcolor: green[500] }} aria-label="active organistions">{this.state.clubstats.activetotal}</Avatar>}
            >
              <ValueLabel value={this.state.clubstats.pertransaction} label="Per Transactions" />
              <ValueLabel value={this.state.clubstats.annual} label="Annual Fee/ Free" />
              <ValueLabel value={this.state.clubstats.irishactive} label="Irish Active" />
              <ValueLabel value={this.state.clubstats.ukactive} label="UK Active" />
              <ValueLabel value={this.state.clubstats.signeduplast30days} label="Sign Up 30 days" />
              <ValueLabel value={this.state.clubstats.signeduplast12months} label="Sign up 1 year" />
              <ValueLabel value={this.state.clubstats.activatedlast30days} label="Activated 30 Days" />
            </DashCard>
          </Grid>

          <Grid item md={3}>
            <DashCard
              title="User Accounts"
              avatar={<Avatar sx={{ bgcolor: this.getColor(this.state.comparisons[0].increase) }} aria-label="value change">-</Avatar>}
            >
              <ValueLabel value={this.state.userstats.activeusers} label="User Accounts" />
              <ValueLabel value={this.state.userstats.newlast30days} label="New Accounts This Month" />
              <ValueLabel value={this.state.userstats.blockedusers} label="Blocked Accounts" />
              <ValueLabel value={this.state.userstats.loggedinlast30days.toLocaleString()} label="Unique Logins This Month" />
              <ValueLabel value={this.state.userstats.totalusers30days.toLocaleString()} label="Total Users This Month" />
            </DashCard>
          </Grid>

          <Grid item md={3}>
            <DashCard
              title="Payments (This/ Last Month)"
              avatar={<Avatar sx={{ bgcolor: this.getColor(this.state.comparisons[0].increase) }} aria-label="value change">-</Avatar>}
            >

              <ValueLabel value={`€${this.state.paymentstats[this.state.paymentstats.length - 1].overall_total.toLocaleString()} /
                      €${this.state.paymentstats[this.state.paymentstats.length - 2].overall_total.toLocaleString()}`} label="Total" />
              <ValueLabel value={`€${this.state.paymentstats[this.state.paymentstats.length - 1].card_total.toLocaleString()}
                          / €${this.state.paymentstats[this.state.paymentstats.length - 2].card_total.toLocaleString()}`} label="Card Payments" />

              <ValueLabel value={`€${this.state.paymentstats[this.state.paymentstats.length - 1].cheque_total.toLocaleString()}
                          / €${this.state.paymentstats[this.state.paymentstats.length - 2].cheque_total.toLocaleString()}`} label="Offline Payments" />

              <ValueLabel value={`${this.state.paymentstats[this.state.paymentstats.length - 1].num_payments.toLocaleString()}
                          / ${this.state.paymentstats[this.state.paymentstats.length - 2].num_payments.toLocaleString()}`} label="Num. Payments" />

              <ValueLabel value={`${this.state.paymentstats[this.state.paymentstats.length - 1].num_transactions.toLocaleString()}
                          / ${this.state.paymentstats[this.state.paymentstats.length - 2].num_transactions.toLocaleString()}`} label="Num. Transactions" />

              <ValueLabel value={`€${this.state.paymentstats[this.state.paymentstats.length - 1].applicationfee.toLocaleString()}
                          /  €${this.state.paymentstats[this.state.paymentstats.length - 2].applicationfee.toLocaleString()}`} label="Application Fees" />

            </DashCard>
          </Grid>

          <Grid item md={4}>
            <DashCard
              title="Transaction by Type (3 Months)"
            >
              <ValueLabel value={`€${this.state.transactionstats[this.state.transactionstats.length - 1].event.value.toLocaleString()}`} label="Events 3 Months" />

              <ReactApexChart options={this.getOptions(this.state.chart[5].options, ["#47bd9a"])}
                series={this.state.chart[5].series.event} type="area" height="60" />

              <ValueLabel
                value={`€${this.state.transactionstats[this.state.transactionstats.length - 1].membership.value.toLocaleString()}`}
                label="Membership 3 Months" />

              <ReactApexChart options={this.getOptions(this.state.chart[5].options, ['#4090cb'])}
                series={this.state.chart[5].series.membership} type="area" height="60" />

              <ValueLabel
                value={`€${this.state.transactionstats[this.state.transactionstats.length - 1].merchandise.value.toLocaleString()}`}
                label="Merchandise 3 Months" />

              <ReactApexChart options={this.getOptions(this.state.chart[5].options, ['#e74c5e'])}
                series={this.state.chart[5].series.merchandise} type="area" height="60" />

              <ValueLabel
                value={`€${this.state.transactionstats[this.state.transactionstats.length - 1].calendar.value.toLocaleString()}`}
                label="Calendar 3 Months" />

              <ReactApexChart options={this.getOptions(this.state.chart[5].options, ['#f9d570'])}
                series={this.state.chart[5].series.calendar} type="area" height="60" />

            </DashCard>
          </Grid>

          <Grid item md={4}>
            <DashCard
              title="Revenue (Year To Date/ Last Year)"
            >
              <ValueLabel value={`€${this.state.revenuestats[this.state.revenuestats.length - 2].applicationfee.toLocaleString()} /
                      €${this.state.revenuestats[this.state.revenuestats.length - 1].applicationfee.toLocaleString()}`} label="Application Fees" />


            </DashCard>
          </Grid>


          <Grid item md={4}>
            <DashCard
              title="Transaction Type (This Year)"
            >
              <ReactApexChart options={this.state.chart[2].options} series={this.state.chart[2].series1}
                                      type="pie" height="320"/>


            </DashCard>
          </Grid>

          <Grid item md={12}>
            <DashCard
              title="Application Fee Collected"
            >
              <ReactApexChart options={this.state.chart[1].options} series={this.state.chart[1].series}
                                      type="area"
                                      width="100%" height="299"/>


            </DashCard>
          </Grid>

          <Grid item md={12}>
            <DashCard
              title="Transaction Types Over Time (By Number)"
            >
              <ReactApexChart options={this.state.chart[3].options} series={this.state.chart[3].series}
                                      type="area"
                                      width="100%" height="299"/>


            </DashCard>
          </Grid>

          <Grid item md={12}>
            <DashCard
              title="Transaction Types Over Time (By Value)"
            >
              <ReactApexChart options={this.state.chart[4].options} series={this.state.chart[4].series}
                                      type="area"
                                      width="100%" height="299"/>


            </DashCard>
          </Grid>

          <Grid item md={12}>
            <DashCard
              title="Payment Value Over Time"
            >
              <ReactApexChart options={this.state.chart[0].options} series={this.state.chart[0].series}
                                      type="area"
                                      width="100%" height="299"/>


            </DashCard>
          </Grid>


        </Grid>
    );
  }
}

export default withRouter(connect(null, { activateAuthLayout })(Dashboard));



import React, { Component } from 'react';
import {Card, CardBody} from 'reactstrap';

class Loading extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {

        return (
            <Card>
                <CardBody>
                    <p>Loading please wait ...</p>
                </CardBody>
            </Card>
        );
    }
}

export default Loading;




// import "@fortawesome/fontawesome-free/css/all.min.css";
// import "bootstrap-css-only/css/bootstrap.min.css";
import React, {Component} from 'react';
import {Card, CardBody} from 'reactstrap';
import { Button } from '@mui/material';

import {MDBDataTable} from 'mdbreact';
import {activateAuthLayout} from '../../../store/actions';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import axios from "axios";
import { getSubscriptionType, formatDate, getApiRoot} from "../../../helpers/Utils";
import LoadingPleaseWait from "../Subpages/LoadingPleaseWait";
import { Grid } from '@mui/material';

class Activeorgs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      page: 1,
      sort: "desc",
      sortcolumn: 'accountactivated',
      data: {
        columns: [
          {
            label: 'Id',
            field: 'id',
            sort: 'asc',
            width: 20
          }, {
            label: 'Shortcode',
            field: 'shortcode',
            sort: 'asc',
            width: 50
          }, {
            label: 'Name',
            field: 'clubname',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Type',
            field: 'type',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Country',
            field: 'country',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Stripe Status',
            field: 'stripestatus',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Created',
            field: 'accountcreated',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Activated',
            field: 'accountactivated',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Invoice Due',
            field: 'invoiceduedate',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Payments(Last 12 Mon)',
            field: 'last12monthstotalvalue',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Revenue (Last 12 Mon)',
            field: 'last12monthsapplicationfee',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Last Payment Made',
            field: 'lastorderdate',
            sort: 'asc',
            width: 100
          },
          {
            label: '',
            field: 'action',
            width: 100
          }
        ],
        rows: []
      }
    };
    console.log("Org constructor");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.saveStateToLocalStorage();
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.props.activateAuthLayout();
    this.hydrateStateWithLocalStorage();

    this._asyncRequest = this.load_data().then(
      response => {
        this._asyncRequest = null;
        this.state.data.rows = response;
        this.setState({
          data: this.state.data,
          isLoading: false
        });
      }
    );

    window.addEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
  }

  saveStateToLocalStorage() {
    localStorage.setItem('activepage', this.state.page);
    localStorage.setItem('activesort', this.state.sort);
    localStorage.setItem('activesortcolumn', this.state.sortcolumn);
  }

  hydrateStateWithLocalStorage() {
    this.state.page = this.getStoreValue('activepage', 1);
    this.state.sort = this.getStoreValue('activesort', 'desc');
    this.state.sortcolumn = this.getStoreValue('activesortcolumn', 'accountactivated');

  }

  getStoreValue(key, default_value) {
    var val = localStorage.getItem(key);
    if (val == null){
      val = default_value;
    }
    return val;
  }



  async load_data() {
    var _this = this;

    return axios.get(getApiRoot() + "/api/club").then(response => {
      if (response.status !== 200)
        throw response.data;

      console.log("Fetched " + response.data.length);
      var allclubs = response.data;
      var rows = [];
      allclubs.forEach(function (item, index) {
        var linkpath = "/view-org/" + item.id;

        if(item.status === 3) {
          var stype = getSubscriptionType(item.subscriptiontype);
          // console.log("Returned sub type: " + stype);
          rows.push({
            id: item.id,
            shortcode: item.shortcode,
            clubname: item.clubname,
            stripestatus: item.stripestatus == '' ? 'N/A' : item.stripestatus,
            type: stype,
            country: item.clubtype === 15 ? 'UK' : 'IE',
            accountcreated: formatDate(item.accountcreated),
            accountactivated: formatDate(item.accountactivated),
            invoiceduedate: stype == "Yearly Fee" ? formatDate(item.invoiceduedate) : 'N/A',
            last12monthstotalvalue: item.last12monthstotalvalue === 0 ? '0' : item.last12monthstotalvalue,
            last12monthsapplicationfee: item.last12monthsapplicationfee === 0 ? '0' :  item.last12monthsapplicationfee,
            lastorderdate: formatDate(item.lastorderdate),
            action: <Link to={linkpath} outline="true" color="primary">
              <Button>View</Button></Link>

          });
        }
      });
      console.log(rows);
      return rows;

    }).catch(err => {
      throw err[1];
    });


  }


  render() {
    if (this.state.isLoading) {
      return (
        <LoadingPleaseWait sectionlabel="Active Organisations" pagelabel="Manage Orgs"/>
      );
    }
    return (
      <Grid container spacing={1}>
        <Grid items xs={12}>

                <h5>Active Organisations</h5>
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      hover
                      striped
                      bordered
                      small
                      data={this.state.data}
                      order={[this.state.sortcolumn, this.state.sort]}
                      onPageChange={e=>this.state.page=e.activePage}
                      onSort={e=> {console.log(e);this.state.sort=e.direction; this.state.sortcolumn = e.column}}
                    />
                  </CardBody>
                </Card>
          </Grid>
      </Grid>
    );
  }
}

export default connect(null, {activateAuthLayout})(Activeorgs);



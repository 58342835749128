import { all } from 'redux-saga/effects'

//public
import loginSaga from './auth/login/saga';

export default function* rootSaga() {
    yield all([
        
        //public
        loginSaga(),
    ])
}
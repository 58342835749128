import React, {Component} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {Link, useParams} from 'react-router-dom';
import {activateAuthLayout} from '../../../store/actions';
import {connect} from 'react-redux';
import Settingmenu from '../Subpages/Settingmenu';
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import {MDBDataTable} from "mdbreact";
import {getSubscriptionType, formatDate, getApiRoot} from "../../../helpers/Utils";
import LoadingPleaseWait from "../Subpages/LoadingPleaseWait";
import { withRouter } from '../../../components/elements/withRouter';
import HomeIcon from '@mui/icons-material/Home';
import { Home } from '@mui/icons-material';
import { history } from '../../../helpers/history';

class ViewOrg extends Component {
  
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      userdata: {
        columns: [
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 100
          }, {
            label: 'Name',
            field: 'username',
            sort: 'asc',
            width: 100
          }, {
            label: 'Contact Number',
            field: 'contactnumber',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Role',
            field: 'role',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Status',
            field: 'userstatus',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Created',
            field: 'created',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Last Login',
            field: 'lastactivity',
            sort: 'asc',
            width: 80
          },
          {
            label: 'Receive Updates',
            field: 'optout',
            sort: 'asc',
            width: 40
          },
          {
            label: '',
            field: 'action',
            width: 100
          }
        ],
        rows: []
      },
      chart: {
        options: {
          chart: {
            type: 'line',
            height: 60,
            sparkline: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
            width: 3
          },
          colors: ['orange', '#4090cb', '#e74c5e', "#f9d570"],
          marker: {
            show: false
          },
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: true
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return ''
                }
              }
            },
            marker: {
              show: false
            }
          }
        },
        series: {
          event: [],
          calendar: [],
          membership: [],
          merchandise: []
        }
      },
      isLoading: true,
      org: {},
      stripe: {}
    }
  }

  componentDidMount() {
    this.props.activateAuthLayout();
    // console.log(this.props);
    //   console.log(location.search);
    this._asyncRequest = this.load_data(this.props.params.orgid).then(
      response => {
        this._asyncRequest = null;

        this.state.org = response.org;
        this.state.userdata.rows = response.users;
        this.setState({
          userdata: this.state.userdata,
          chart: this.state.chart,
          data: this.state.data,
          org: this.state.org,
          stripe: this.state.stripe,
          isLoading: false
        });
      }
    ).catch(err => {
      console.log("problem getting org info");
      this.setState({
        isLoading: false
      });
    });
  }

  goBack() {
    history.location.goBack();
  }

  async load_data(orgid) {
    var _this = this;

    return axios.get(getApiRoot() + "/api/club/" + orgid).then(response => {
      if (response.status !== 200)
        throw response.data;

      //console.log(response.);
      _this.loadTransactionData(response.data.stats);
      console.log("Fetched club");
      console.log(response);

      var org = response.data;
      _this.setStripeStatus(org);
      return axios.get(getApiRoot() + "/api/user/?clubid=" + orgid).then(userresponse => {
        if (userresponse.status !== 200)
          throw userresponse.data;

        //console.log(response.);
        console.log("Fetched users");
        console.log(userresponse);

        return {
          org: org,
          users: userresponse.data
        };
      }).catch(err => {
        throw err[1];
      });
    }).catch(err => {
      throw err[1];
    });


  }

  setStripeStatus(org) {
    if (org.status != 3) {
      this.state.stripe = {
        status: 'No Account',
        display_name: "",
        charge_enabled: '',
        payments: '',
        transfers: '',
        verification: '',
        email: '',
        country: '',
        default_currency: '',
        details_submitted: '',
      };
      return;
    }

    this.state.stripe.status = this.getStripeStatus(org);
    this.state.stripe.display_name = org.stripe.display_name;
    this.state.stripe.charge_enabled = org.stripe.charge_enabled;
    this.state.stripe.transfer_enabled = org.stripe.transfer_enabled;
    // this.state.stripe.payments = org.stripe.capabilities.card_payments;
    // this.state.stripe.legacy_payments = org.stripe.capabilities.legacy_payments;
    // this.state.stripe.transfers = org.stripe.capabilities.platform_payments == 'active' || org.stripe.capabilities.legacy_payments === 'active' ? 'active' : 'inactive';
    this.state.stripe.verification = this.get_verification_summary(org.stripe.verification);
    this.state.stripe.email = org.stripe.email;
    this.state.stripe.country = org.stripe.country;
    this.state.stripe.default_currency = org.stripe.default_currency;
    this.state.stripe.details_submitted = org.stripe.details_submitted === true ? "Yes" : "No";

  }

  get_verification_summary(verification) {
    var ans = [];
    ans.push(verification.contacted ? "Contacted: yes" : "Contacted: no");
    ans.push(verification.disabled_reason == null ? "Disabled Reason: none" : "Disabled Reason: " + verification.disabled_reason);
    ans.push(verification.due_by == null ? "Due By: n/a" : "Due By: " + new Date(verification.due_by*1000).toLocaleDateString());
    if (verification.errors && verification.errors.length > 0) {
      var errors = verification.errors.map(function (item) {
        return JSON.stringify(item);
      });

      ans.push("Errors: " + errors.join(", "));
    }
    if (verification.fields_needed && verification.fields_needed.length > 0) {
      ans.push("Fields Needed: " + verification.fields_needed.join(", "));
    }
    if (verification.pending_verification && verification.pending_verification.length > 0) {
      ans.push("Pending Verification: " + verification.pending_verification.join(", "));
    }

    return ans.join(" - ")
  }

  getStripeStatus(org) {
    var isActive = org.stripe.charge_enabled && org.stripe.transfer_enabled ;

    if (isActive) {
      return "Active";
    }

    if (!org.stripe.charge_enabled && !org.stripe.transfer_enabled) {
      return "All Blocked";
    }

    if (!org.stripe.transfer_enabled) {
      return "Transfers Blocked";
    }

    return "Payments Blocked";
  }

  loadTransactionData(stats) {

    var transactionstats = this.getTransactionValueSeries(stats);

    console.log("Fetched transaction stats");
    this.state.chart.series.event = [{
      name: 'Events',
      data: transactionstats[0].data.splice(transactionstats[0].data.length - 13, transactionstats[0].data.length - 1)
    }];
    this.state.chart.series.membership = [{
      name: 'Membership',
      data: transactionstats[1].data.splice(transactionstats[1].data.length - 13, transactionstats[1].data.length - 1)
    }];
    this.state.chart.series.merchandise = [{
      name: 'Merchandise',
      data: transactionstats[2].data.splice(transactionstats[2].data.length - 13, transactionstats[2].data.length - 1)
    }];
    this.state.chart.series.calendar = [{
      name: 'Calendar',
      data: transactionstats[3].data.splice(transactionstats[3].data.length - 13, transactionstats[3].data.length - 1)
    }];
  }

  getTransactionValueSeries(transactionstats) {
    var events = [];
    var merchandise = [];
    var calendar = [];
    var membership = [];
    transactionstats.forEach(function (item, index) {
      events.push({
        x: item.date,
        y: item.event.value
      });
      merchandise.push({
        x: item.date,
        y: item.merchandise.value
      });
      calendar.push({
        x: item.date,
        y: item.calendar.value
      });
      membership.push({
        x: item.date,
        y: item.membership.value
      });
    });
    return [{
      name: 'Event Entries',
      data: events
    }, {
      name: 'Membership',
      data: membership
    }, {
      name: 'Merchandise',
      data: merchandise
    }, {
      name: 'Calendar',
      data: calendar
    }]
  }

  render() {
    if (this.state.isLoading) {
      return (
        <LoadingPleaseWait sectionlabel="View Org Details" pagelabel="Manage Orgs"/>
      );

    }
    var public_url = "http://www.itsplainsailing.com/club/" + this.state.org.shortcode;
    var statuspill = <span className="badge badge-pill badge-success">Active</span>;
    if (this.state.org.status != 3) {
      statuspill = <span className="badge badge-pill badge-danger">Inctive - {this.state.org.status}</span>;
    }

    var stripestatus = <span className="badge badge-pill badge-info">Not Applicable</span>;
    console.log("Setting stripe status " + this.state.stripe.status);
    if (this.state.stripe.status != 'No Account') {
      if (this.state.stripe.status == 'Active') {
        stripestatus = <span className="badge badge-pill badge-success">Active</span>;
      } else if (this.state.stripe.status == 'All Blocked') {
        stripestatus = <span className="badge badge-pill badge-danger">All Blocked</span>;
      } else {
        stripestatus = <span className="badge badge-pill badge-warning">{this.state.stripe.status}</span>;
      }
    }

    return (
      <React.Fragment>
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col sm="10">
                  <h4 className="page-title">View Org Details {statuspill} </h4>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/Dashboard"><HomeIcon/></Link></li>
                    <li className="breadcrumb-item "><Link to="/active-orgs" >Active Orgs</Link></li>
                    <li className="breadcrumb-item ">{this.state.org.id} - {this.state.org.clubname} (<a target="_blank"
                                                                                   href={public_url}>{this.state.org.shortcode}</a>)
                    </li>
                    <li className="breadcrumb-item ">{this.state.org.clubcontactname}</li>
                    <li className="breadcrumb-item ">{this.state.org.clubcontactemail}</li>
                    <li className="breadcrumb-item active">{this.state.org.clubcontactnumber}</li>
                  </ol>
                </Col>
                <Col sm="2">
                  <div className="float-right d-none d-md-block">
                    {/*<Settingmenu/>*/}
                  </div>
                </Col>
              </Row>
            </div>

            <Row>
              <Col xl="8">
                <Card>
                  <CardBody>
                    <h4 className="mt-0 header-title">Transaction by Type
                      ({this.state.org.stats && this.state.org.stats[this.state.org.stats.length - 1].date})</h4>
                    <div>
                      <div className="wid-earning">
                        <div className="row">
                          <div className="col-md-4">
                            <div>
                              <h5
                                className="mt-0">€{this.state.org.stats && this.state.org.stats[this.state.org.stats.length - 1].event.value.toLocaleString()}</h5>
                              <p className="text-muted mb-md-0">Events This Month</p>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div id="chart1">
                              <ReactApexChart options={this.state.chart.options}
                                              series={this.state.chart.series.event} type="area" height="60"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wid-earning">
                        <div className="row">
                          <div className="col-md-4">
                            <div>
                              <h5
                                className="mt-0">€{this.state.org.stats && this.state.org.stats[this.state.org.stats.length - 1].membership.value.toLocaleString()}</h5>
                              <p className="text-muted mb-md-0">Membership This Month</p>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div id="chart2">
                              <ReactApexChart options={this.state.chart.options}
                                              series={this.state.chart.series.membership} type="area" height="60"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wid-earning">
                        <div className="row">
                          <div className="col-md-4">
                            <div>
                              <h5
                                className="mt-0">€{this.state.org.stats && this.state.org.stats[this.state.org.stats.length - 1].merchandise.value.toLocaleString()}</h5>
                              <p className="text-muted mb-md-0">Merchandise This Month</p>

                            </div>
                          </div>
                          <div className="col-md-8">
                            <div id="chart3">
                              <ReactApexChart options={this.state.chart.options}
                                              series={this.state.chart.series.merchandise} type="area" height="60"/>
                            </div>
                          </div>
                        </div>
                        <div className="wid-earning">
                          <div className="row">
                            <div className="col-md-4">
                              <div>
                                <h5
                                  className="mt-0">€{this.state.org.stats && this.state.org.stats[this.state.org.stats.length - 1].calendar.value.toLocaleString()}</h5>
                                <p className="text-muted mb-md-0">Calendar This Month</p>

                              </div>
                            </div>
                            <div className="col-md-8">
                              <div id="chart3">
                                <ReactApexChart options={this.state.chart.options}
                                                series={this.state.chart.series.calendar} type="area" height="60"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card className="bg-pattern">
                  <CardBody>
                    <h5 className="mb-3 mt-0">Summary - {statuspill} - {getSubscriptionType(this.state.org.subscriptiontype)}</h5>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0"><span
                          className="text-success mr-2"> €{this.state.org.stats && this.state.org.last12monthstotalvalue.toLocaleString()} </span>
                        </p>
                      </Col>
                      <Col>
                        <p className="text-muted mb-0"> Total Value (12 Months)</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0"><span
                          className="text-success mr-2">€{this.state.org.stats && this.state.org.last12monthsapplicationfee.toLocaleString()} </span>
                        </p>
                      </Col>
                      <Col>
                        <p className="text-muted mb-0"> Revenue (12 Months)</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0"><span
                          className="text-success mr-2"> {this.state.org.stats && formatDate(this.state.org.lastorderdate)} </span>
                        </p>
                      </Col>
                      <Col>
                        <p className="text-muted mb-0"> Last Payment Date</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0"><span
                          className="text-success mr-2"> {this.state.org.stats && formatDate(this.state.org.accountactivated)} </span>
                        </p>
                      </Col>
                      <Col>
                        <p className="text-muted mb-0"> Account Activated</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0"><span
                          className="text-success mr-2"> {this.state.org.stats && formatDate(this.state.org.accountcreated)} </span>
                        </p>
                      </Col>
                      <Col>
                        <p className="text-muted mb-3"> Account Created</p>
                      </Col>
                    </Row>

                  </CardBody>
                </Card>
                <Card className="bg-pattern">
                  <CardBody>
                    <h5 className="mb-3 mt-0">Stripe Details - {stripestatus}</h5>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0"> Name / Country / Currency</p>
                      </Col>
                      <Col>
                        <p className="text-muted mb-0"><span
                          className="text-success mr-2"> {this.state.stripe.display_name} / {this.state.stripe.country} / {this.state.stripe.default_currency}</span>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0"> Account Email </p>
                      </Col>
                      <Col>
                        <p className="text-muted mb-0"><span
                          className="text-success mr-2"> {this.state.stripe.email} </span></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0"> Payments</p>
                      </Col>
                      <Col>
                        <p className="text-muted mb-0"><span
                          className="text-success mr-2"> {this.state.stripe.charge_enabled ?
                          <span className="badge badge-pill badge-success">Active</span> :
                          <span className="badge badge-pill badge-danger">Inactive</span> }
                        </span>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0"> Transfers</p>
                      </Col>
                      <Col>
                        <p className="text-muted mb-0"><span
                          className="text-success mr-2"> {this.state.stripe.transfer_enabled ?
                          <span className="badge badge-pill badge-success">Active</span> :
                          <span className="badge badge-pill badge-danger">Inactive</span>} </span></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0"> Details Submitted</p>
                      </Col>
                      <Col>
                        <p className="text-muted mb-0"><span
                          className="text-success mr-2"> {this.state.stripe.details_submitted} </span></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-3"><span
                          className="mr-2">Verification Summary </span></p>
                      </Col>
                      <Col className="mb-3">
                        <p className="text-muted mb-0 mb-3"><span
                          className="text-success mr-2 ">  {this.state.stripe.verification} </span></p>
                      </Col>
                    </Row>


                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      hover
                      striped
                      bordered
                      small
                      data={this.state.userdata}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default withRouter(connect(null, {activateAuthLayout})(ViewOrg));



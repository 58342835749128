export const CHECK_LOGIN = 'check_login';
export const LOGIN_USER_SUCCESSFUL = 'login_user_successfull';

export const CHECK_OTP = 'check_otp';
export const VALIDATE_OTP_SUCCESS = 'validate_otp_success';
export const VALIDATE_OTP_ERROR = 'validate_otp_error';

export const APILOGIN_FAILED = 'api_failed';
export const ERROR_CLEAR = 'error_clear';
export const EMPTY_ERROR = 'empty_error';
